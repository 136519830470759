import './index.css';
import movie1 from './movie1.jpg';
import movie2 from './movie2.jpeg';
import movie3 from './movie3.jpeg';
import movie4 from './movie4.jpeg';
import movie5 from './movie5.jpeg';
import movie6 from './movie6.jpeg';
import movie7 from './movie7.jpeg';
import movie8 from './movie8.jpeg';

export function Movie1({ mname, year, rating }) {
    return (
      <div className="card">
        <img src={movie1} alt=""></img>
        <p><b>Movie Name:&nbsp;</b>{mname}</p>
        <p><b>Release Year:&nbsp;</b>{year}</p>
        <p><b>Rating:&nbsp;</b>{rating}</p>
      </div>
    );
}

export function Movie2({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie2} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
export function Movie3({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie3} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
export function Movie4({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie4} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
export function Movie5({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie5} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
export function Movie6({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie6} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
export function Movie7({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie7} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
export function Movie8({ mname, year, rating }) {
  return (
    <div className="card">
      <img src={movie8} alt=""></img>
      <p><b>Movie Name:&nbsp;</b>{mname}</p>
      <p><b>Release Year:&nbsp;</b>{year}</p>
      <p><b>Rating:&nbsp;</b>{rating}</p>
    </div>
  );
}
