
import './App.css';
import {Movie1,Movie2,Movie3,Movie4,Movie5,Movie6,Movie7,Movie8} from './Movies';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3><b>Harry Potter Movies List</b></h3>
        <Movie1 mname="Harry Potter and the Sorcerer's Stone" year="2001" rating="7.6/10" />
        <Movie2 mname="Harry Potter and the Chamber of Secrets" year="2002" rating="7.4/10" />
        <Movie3 mname="Harry Potter and the Prisoner of Azkaban" year="2004" rating="7.9/10" />
        <Movie4 mname="Harry Potter and the Goblet of Fire" year="2005" rating="7.7/10" />
        <Movie5 mname="Harry Potter and the Order of the Phoenix" year="2007" rating="7.5/10" />
        <Movie6 mname="Harry Potter and the Half-Blood Prince" year="2009" rating="7.6/10" />
        <Movie7 mname="Harry Potter and the Deathly Hallows: Part 1" year="2010" rating="7.7/10" />
        <Movie8 mname="Harry Potter and the Deathly Hallows: Part 2" year="2011" rating="8.1/10" />
      </header>
    </div>
  );
}

export default App;


